<!-- @format -->

<template>
    <client-only
        ><Lazy-el-dialog
            v-model="dialog"
            modal-class="mobile-el-trialFunds-dialog"
            :show-close="false"
            title=""
            width="100%"
            destroy-on-close
            center
            align-center
            @close="closeFn"
        >
            <div
                class="z-[700] m-auto overflow-x-hidden overflow-y-auto relative top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center border-radius-0 transition-all duration-300 pointer-events-none opacity-100 pointer-events-auto translate-y-0"
            >
                <div class="w-full">
                    <base-img class="w-full" name="tyj" type="jpg" path="images/activity" />
                    <div
                        @click="closeFn"
                        class="absolute z-[2] top-[2%] right-[2%] w-[40px] h-[40px] text-color-text-2 text-[18px] flex items-center justify-center"
                    >
                        <base-img class="w-[30px]" name="tyj4" type="png" path="images/activity" />
                    </div>

                    <div
                        style="color: #820c0c"
                        class="absolute w-full px-[29%] top-[28%] flex flex-col justify-center items-center gap-[4px]"
                    >
                        <span class="self-start pl-[10px] font-black">R$</span>
                        <span class="text-[46px]" style="font-family: FZ">30</span>
                    </div>
                    <div
                        style="color: #820c0c"
                        class="absolute w-full px-[25%] top-[53%] flex flex-col justify-center items-center gap-[10px]"
                    >
                        <span class="font-black" style="font-family: Arial-Black">Bonus de experiência</span>
                        <span class="text-center text-[11px]"
                            >Registre-se agora e ganhe um bonus de experiéncia de R$30 jogue gratuitamente todos os
                            jogos populares.</span
                        >
                    </div>

                    <div class="absolute w-full px-[25%] top-[76%] flex flex-col justify-center items-center">
                        <div class="sent">Registre-se agora</div>
                    </div>
                    <div
                        style="color: #b59581"
                        class="text-[11px] absolute w-full px-[9%] pl-[13%] bottom-[7.5%] flex flex-col justify-center items-center"
                    >
                        O dinheiro ganho através do bônus pode ser co -nvertido em dinheiro real através de depósitos.
                    </div>
                </div>
            </div>
        </Lazy-el-dialog></client-only
    >
</template>

<script setup lang="ts">
    const {locale, t} = useI18n();
    const dialog = useTrialFunds();
    const isLogin = useIsLogin();
    const route = useRoute();

    const closeFn = () => {
        closePopup("trialFunds");
    };

    watchEffect(() => {
        dialog.value = getHashValue(route.hash) === "trialFunds";
    });
</script>

<style lang="scss">
    .mobile-el-trialFunds-dialog {
        backdrop-filter: blur(12px);
        .el-dialog {
            background: transparent;
            box-shadow: none;
            width: 100%;
            max-width: calc(var(--maxWidth) - 40px);
            border-radius: 10px;

            .login-close .el-icon {
                font-size: 18px;
                color: var(--el-border-color-darker);
            }
            .sent {
                background-image: linear-gradient(0deg, #9b22ad 0%, #ea49ce 100%), linear-gradient(#ad2bf1, #ad2bf1);
                background-blend-mode: normal, normal;
                border-style: solid;
                border-width: 1px;
                border-image-source: linear-gradient(0deg, #f458b0 0%, #ffb3ee 100%);
                border-image-slice: 1;
                padding: 8px 20px;
                border-radius: 20px;
                color: #ffffff;
            }
        }
    }
</style>
